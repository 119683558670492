import { request } from "@/utils/functions/request";
export const postAnnouncement = async (formData: any) => {
  return request("/account/announcement/index.php", {
    method: "POST",
    body: formData
  });
};
export const editAnnouncement = async (id: string | number, formData: any) => {
  if (!id) return;
  return request(`/account/announcement/index.php?id=${id}`, {
    method: "POST",
    body: formData
  });
};
export const deleteImageAnnouncement = async (picture: string) => {
  return request(`/account/announcement/index.php?pic=${picture}`, {
    method: "delete"
  });
};