import { TModalOverlayComponent } from "@/shared/types/types";
import styles from "./index.module.scss";
import { FC } from "react";
import { motion } from "framer-motion";
interface IModalOverlay extends TModalOverlayComponent {
  onClose: (() => void) | false;
  overlayClass: string;
}
const ModalOverlay: FC<IModalOverlay> = props => {
  const {
    onClose,
    overlayClass
  } = props;
  return <motion.div className={`${styles.modalOverlay} ${styles[overlayClass]}`} initial={{
    opacity: 0
  }} animate={{
    opacity: 0.6
  }} exit={{
    opacity: 0
  }} onClick={onClose ? onClose : () => {}} data-sentry-element="unknown" data-sentry-component="ModalOverlay" data-sentry-source-file="index.tsx"></motion.div>;
};
export default ModalOverlay;