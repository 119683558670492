import styles from "./index.module.scss";
import { FC } from "react";
import Button from "@/shared/components/Button";
import { Icon } from "@/shared/components/Icon";
interface IPointsItem {
  point: {
    code: string;
    work_time: string;
    work_hours: any;
    "address-source": string;
    latitude: number;
    longitude: number;
    location: {
      address: string;
      latitude: number;
      longitude: number;
    };
    uuid: string;
  };
  nameCompany: string;
  openBalloon: (coordinates: [number, number], uuid: string) => void;
  handleChoosePoint: (latitude: number, longitude: number) => void;
}
export const PointsItem: FC<IPointsItem> = props => {
  const {
    point,
    nameCompany,
    openBalloon,
    handleChoosePoint
  } = props;
  return <div className={styles.card__point} data-sentry-component="PointsItem" data-sentry-source-file="index.tsx">
      <Icon name={"Tag"} className={styles.card__icon} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
      <div className={styles.card__point_content}>
        <p className={styles.card__point_choosed}>
          Пункт выдачи {nameCompany}&nbsp;
          {point.location && point.location.address ? point.location.address : point["address-source"]}
          <span>{point.work_time ? point.work_time : "09:00 - 20:00"}</span>
        </p>
        <div className={styles.card__point_actions}>
          <Button data-choose-coordinates={[point.location.latitude, point.location.longitude]} className={styles.card__point_choose} onClick={() => handleChoosePoint(point.location.latitude, point.location.longitude)} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            <>Выбрать</>
          </Button>
          <button className={styles.card__point_show} onClick={() => openBalloon([point.location.latitude, point.location.longitude], point.uuid)}>
            Показать на карте
          </button>
        </div>
      </div>
    </div>;
};