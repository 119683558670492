import { callMessage } from "./callMessage";
export const phoneValidate = (str: string) => {
  let a = str.replace(/[+\s-/\_]/g, "").replace(/^7/, "");
  const errorMessage = () => callMessage("Ошибка!", "Проверьте правильность ввода номера телефона.", false);
  if (!str) {
    errorMessage();
    return false;
  }
  if (a[0] !== "9") {
    errorMessage();
    return false;
  } else if (a.length !== 10) {
    errorMessage();
    return false;
  } else {
    return true;
  }
};