import styles from "./index.module.scss";
import { FC } from "react";
import { IPopup } from "@/shared/types/types";
import Button from "@/shared/components/Button";
import Modal from "@/shared/components/Modal";
export const ConfirmPopup: FC<IPopup> = ({
  isOpen,
  onClose,
  title,
  text,
  action
}) => {
  return <Modal isOpen={isOpen} title={title} onClose={onClose} data-sentry-element="Modal" data-sentry-component="ConfirmPopup" data-sentry-source-file="index.tsx">
      <div className={styles.wrap}>
        <p className={styles.subtitle}>{text}</p>
        <div className={styles.buttonWrap}>
          <Button onClick={action} className={styles.confirm} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            <>Подтвердить</>
          </Button>
          <Button onClick={onClose} className={styles.cancel} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            <>Отменить</>
          </Button>
        </div>
      </div>
    </Modal>;
};