"use client";

import styles from "./index.module.scss";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { YMap, YMapComponentsProvider, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapListener, YMapControls, YMapGeolocationControl, YMapZoomControl, YMapMarker, YMapCustomClusterer } from "ymap3-components";
import * as YMaps from "@yandex/ymaps3-types";
import Input from "@/shared/components/Input";
import { PointsItem } from "./PointsItem";
import { useAppDispatch } from "@/shared/redux/hooks";
import { setDeliveryNotLicensePoint } from "@/shared/redux/features/orderSlice";
import { callMessage } from "@/utils/functions/callMessage";
import { defaultLocation, YMAP_API_KEY } from "@/shared/constants/constants";
import CustomMapMarker from "@/shared/components/CustomMapMarker";
import { Icon } from "@/shared/components/Icon";
interface IPointsList {
  points: IPoint[];
  company: string;
  onClose: () => void;
}
type IOnUpdate = {
  location: typeof defaultLocation;
  mapInAction: boolean;
};
type IPoint = {
  code: string;
  name: string;
  uuid: string;
  addressComment: string;
  nearestStation: string;
  workTime: string;
  phones: {
    number: string;
  }[];
  email: string;
  note: string;
  type: string;
  ownerCode: string;
  takeOnly: boolean;
  isHandout: boolean;
  isReception: boolean;
  isDressingRoom: boolean;
  isLtl: boolean;
  haveCashless: boolean;
  haveCash: boolean;
  haveFastPaymentSystem: boolean;
  allowedCod: boolean;
  officeImageList: {
    url: string;
  }[];
  workTimeList: {
    day: number;
    time: string;
  }[];
  workTimeExceptionList: any[];
  weightMin: number;
  weightMax: number;
  location: {
    countryCode: string;
    regionCode: number;
    region: string;
    cityCode: number;
    city: string;
    fiasGuid: string;
    postalCode: string;
    longitude: number;
    latitude: number;
    address: string;
    addressFull: string;
    cityUuid: string;
  };
  fulfillment: boolean;
  geometry: {
    type: string;
    coordinates: [number, number];
  };
  latitude: number | string;
  addressFias?: any;
  address?: any;
  longitude: number | string;
};
export const PointsList: FC<IPointsList> = props => {
  const {
    points,
    company,
    onClose
  } = props;

  // var textInput = document.getElementById("search");
  // textInput.addEventListener("keydown", function(event) {
  //   if(event.keyCode == 13){
  //     // Поиск адреса по Enter
  //     ymaps3.search({
  //       'text': textInput.value
  //     }).then(function (res) {
  //       console.log(res[0])
  //     })
  //   }
  // });

  const prePoints: IPoint[] = company === "boxberry" ? Object.keys(points).map(key => {
    const ltd = Number(points[key].GPS.split(",")[0]);
    const lon = Number(points[key].GPS.split(",")[1]);
    return {
      ...key,
      type: "Feature",
      id: ltd + lon,
      geometry: {
        type: "Point",
        coordinates: [lon, ltd]
      },
      code: key,
      work_time: points[key].WorkShedule,
      location: {
        address: points[key].CityName + " " + points[key].AddressReduce,
        latitude: points[key].GPS.split(",")[0],
        longitude: points[key].GPS.split(",")[1]
      },
      name: points[key].AddressReduce
    };
  }) : company === "pochta" ? points.map(key => {
    return {
      ...key,
      type: "Feature",
      id: Number(key.longitude) + Number(key.latitude),
      geometry: {
        type: "Point",
        coordinates: [Number(key.longitude), Number(key.latitude)]
      },
      code: key.addressFias.regGarId,
      work_time: "Не указано",
      location: {
        address: key.address.place + " " + key.address.street + " д." + key.address.house,
        latitude: Number(key.latitude),
        longitude: Number(key.longitude)
      },
      name: key.address.place
    };
  }) : points.map((point: any) => {
    return {
      ...point,
      type: "Feature",
      id: point.location.longitude + point.location.latitude,
      geometry: {
        type: "Point",
        coordinates: [point.location.longitude, point.location.latitude]
      }
    };
  });
  const newPoints = useMemo(() => {
    return prePoints.filter((item, index, self) => index === self.findIndex(t => t.id === item.id));
  }, []);
  const wrapRef = useRef(null);
  const [isLoadMap, setIsLoadMap] = useState<boolean>(false);
  const [popupOpen, setPopupOpen] = useState<false | string>(false);
  const [location, setLocation] = useState<typeof defaultLocation>(defaultLocation);
  const [currentPointsPage, setCurrentPointsPage] = useState<number>(10);
  const [currentPointList, setCurrentPointList] = useState<IPoint[]>(newPoints.slice(0, currentPointsPage));
  const [isSearched, setIsSearched] = useState(false);
  const [tabName, setTabName] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [ymap, setYmap] = useState<YMaps.YMap>();
  const contentRef = useRef<HTMLDivElement>(null);
  const geoButtonRef = useRef(null);
  const [userCords, setUserCords] = useState<YMaps.LngLat>([]);
  const nameCompany = company === "boxberry" ? "Boxberry" : company === "cdek" ? "СДЭК" : "Почта России";
  const Marker = useCallback((feature: any) => {
    const point = !!feature.feature ? feature.feature : feature;
    return <CustomMapMarker point={point} isOpenPopup={popupOpen} handleChoose={() => handleChoosePoint(point.geometry.coordinates[1], point.geometry.coordinates[0])} handleClosePopup={() => setPopupOpen(false)} openPopup={() => setPopupOpen(point.id)} />;
  }, [popupOpen]);
  const onUpdate = useCallback(({
    location,
    mapInAction
  }: IOnUpdate) => {
    if (!mapInAction) {
      setLocation({
        center: location.center,
        zoom: location.zoom
      });
    }
  }, []);
  const changeTab = (arg: boolean) => {
    setTabName(arg);
  };
  const choiceShopInMap = (cords: number[], uuid: string) => {
    const newLocation = {
      center: [cords[1], cords[0]],
      zoom: 18
    };
    setPopupOpen(uuid);
    setLocation(newLocation);
  };
  const handleChoosePoint = (latitude: number, longitude: number) => {
    const point = newPoints.find(el => el.location.latitude == latitude && el.location.longitude == longitude);
    dispatch(setDeliveryNotLicensePoint({
      deliveryCompany: company,
      point: point.code,
      deliveryName: nameCompany,
      deliveryAddress: point.location && point.location.address ? point.location.address : point["address-source"],
      pointWorkTime: point.work_time ?? ""
    }));
    onClose();
  };
  const handleShowMore = () => {
    setCurrentPointsPage(currentPointsPage + 10);
    setCurrentPointList(newPoints.slice(0, currentPointsPage + 10));
  };
  const handleSearch = (e: string) => {
    const filtredList = newPoints.filter(el => el.name.toLowerCase().includes(e));
    if (!e) setCurrentPointList(newPoints.slice(0, currentPointsPage));else setCurrentPointList(filtredList);
  };
  useEffect(() => {
    if (userCords.length) return;
    navigator.geolocation.getCurrentPosition(function (position) {
      setUserCords([position.coords.latitude, position.coords.longitude]);
      const userLocation = {
        center: [position.coords.longitude, position.coords.latitude],
        zoom: 12
      };
      setLocation(userLocation);
    }, function (error) {
      callMessage("Ошибка!", "У вас установлен запрет на определение местоположения. Измените это в настройках браузера и повторите попытку.", false);
    });
  }, [userCords.length]);
  const cluster = useCallback((coordinates, features) => <YMapMarker coordinates={coordinates}>
        <span style={{
      borderRadius: "50%",
      background: "var(--primary-color)",
      color: "white",
      width: 42,
      height: 42,
      outline: "solid 3px var(--primary-color)",
      outlineOffset: "3px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
          {features.length}
        </span>
      </YMapMarker>, []);
  useEffect(() => {}, []);
  return <div className={styles.wrap} ref={wrapRef} data-sentry-component="PointsList" data-sentry-source-file="index.tsx">
      <div className={styles.tab_buttons}>
        <button onClick={() => changeTab(false)} className={`${!tabName ? styles.button_active : styles.button} `}>
          Список
        </button>
        <button onClick={() => changeTab(true)} className={`${tabName ? styles.button_active : styles.button} `}>
          На карте
        </button>
      </div>
      <div className={styles.content} ref={contentRef}>
        <div className={`${styles.shops_list} ${!tabName ? styles.show_list : styles.hidden_list}`}>
          <div className={styles.searchWrap}>
            <p className={styles.searchText}>выберите пункт самовывоза на карте или используйте поиск</p>
            <Input id="search-point-input" label={"Введите название улицы"} className={styles.inputWrap} icon={<Icon name={"Search"} />} data-sentry-element="Input" data-sentry-source-file="index.tsx">
              <>
                <input id="search-point-input" className={styles.input} placeholder=" " name="point" onChange={e => handleSearch(e.target.value)} autoComplete="off" />
              </>
            </Input>
          </div>
          <div className={styles.point_list}>
            {currentPointList.map((point, index: number) => <PointsItem key={`${index + "pointsItem" + "pointListkey"}`} point={point} nameCompany={nameCompany} openBalloon={choiceShopInMap} handleChoosePoint={handleChoosePoint} />)}
            {currentPointList.length < newPoints.length && !isSearched && <button className={styles.showMoreButton} onClick={handleShowMore}>
                показать ещё
              </button>}
          </div>
        </div>
        <div className={`${styles.map_side} ${tabName ? styles.show_map : styles.hidden_map}`} style={{
        height: contentRef.current ? contentRef.current.offsetHeight - 24 : 400
      }}>
          <YMapComponentsProvider apiKey={YMAP_API_KEY} lang="ru_RU" onLoad={() => setIsLoadMap(true)} data-sentry-element="YMapComponentsProvider" data-sentry-source-file="index.tsx">
            {isLoadMap && <YMap key="map" ref={(ymap: YMaps.YMap) => setYmap(ymap)} location={location} mode="vector" theme="light">
                <YMapDefaultSchemeLayer />
                <YMapDefaultFeaturesLayer />
                <YMapCustomClusterer cluster={cluster} marker={Marker} gridSize={128} features={newPoints} />
                {/*<YMapCollection>*/}
                {/*  {newPoints && newPoints.map((point: any, index: number) => (*/}
                {/*    <Marker key={index} feature={point}/>*/}
                {/*  ))}*/}
                {/*</YMapCollection>*/}
                <YMapListener onUpdate={onUpdate} />
                <YMapControls position="right">
                  <YMapZoomControl />
                  <YMapGeolocationControl ref={geoButtonRef} />
                </YMapControls>
              </YMap>}
          </YMapComponentsProvider>
        </div>
      </div>
    </div>;
};