import styles from "./index.module.scss";
import Modal from "@/shared/components/Modal";
import { FC } from "react";
import { IPopup } from "@/shared/types/types";
import Image from "next/image";
import { li } from "@/utils/functions/li";
import { sizeList } from "@/shared/helpers/data/sizeList";
const SizesTablePopup: FC<IPopup> = props => {
  const {
    isOpen,
    onClose,
    category
  } = props;
  return <Modal isOpen={isOpen} title={"Таблица размеров"} onClose={onClose} containerClassname={styles.modal_container} className={styles.modal} data-sentry-element="Modal" data-sentry-component="SizesTablePopup" data-sentry-source-file="index.tsx">
      <div className={styles.wrap}>
        {category === "shoes" ? <>
            <div className={styles.tableWrap}>
              <div className={styles.tablePanel}>
                <table className={styles.table}>
                  <tbody>
                    <tr>
                      <th title="Длина стопы, см">Длина стопы, см</th>
                      {sizeList.footLength.map((length, index) => <td key={length + index}>{length}</td>)}
                    </tr>
                    <tr>
                      <th title="Росийский рсазмер RU">Российский размер RU</th>
                      {sizeList.footRuSize.map((size, index) => <td key={size + index}>{size}</td>)}
                    </tr>
                    <tr>
                      <th title="Европейский размер EU">Европейский размер EU</th>
                      {sizeList.footEuSize.map((size, index) => <td key={size + index}>{size}</td>)}
                    </tr>
                    <tr>
                      <th title="Английский размер UK">Английский размер UK</th>
                      {sizeList.footUkSize.map((size, index) => <td key={size + index}>{size}</td>)}
                    </tr>
                    <tr>
                      <th title="Американский размер US">Американский размер US</th>
                      {sizeList.footUsSize.map((size, index) => <td key={size + index}>{size}</td>)}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h2 className={styles.subtitle}>Как снять мерки</h2>
            <p className={styles.text}>
              Чтобы выбрать правильный размер обуви, рекомендуем измерить стопу в соответствии с приведёнными ниже
              правилами.
            </p>
            <div className={styles.description}>
              <p className={styles.text}>
                Наденьте чулки или носки, которые вы обычно носите с таким видом обуви.Встаньте прямо на твёрдой плоской
                поверхности, касаясь стены пятками. При необходимости попросите кого-нибудь помочь вам снять мерки.
                Прижав пятки к стене, измерьте длину стопы от основания пятки до крайней точки самого длинного пальца.
                Обязательно измерьте обе стопы и используйте наибольшую величину для определения размера.
              </p>
              <Image src={li("sizes-img-clothes.webp")} width={50} height={50} alt="Размер стопы" className={styles.img} />
            </div>
          </> : <>
            <div className={styles.tableWrap}>
              <div className={styles.tablePanel}>
                <table className={styles.table}>
                  <tbody>
                    <tr>
                      <th title="Российский размер RU">Российский размер RU</th>
                      {sizeList.ruSize.map((size, index) => <td key={size + index + "ru"}>{size}</td>)}
                    </tr>
                    <tr>
                      <th title="Международный размер INT">Международный размер INT</th>
                      {sizeList.INT.map((size, index) => <td key={size + index + "INT"}>{size}</td>)}
                    </tr>
                    <tr>
                      <th title="Обхват груди, см">Обхват груди, см</th>
                      {sizeList.chestCircumference.map((size, index) => <td key={size + index + "grudi"}>{size}</td>)}
                    </tr>
                    <tr>
                      <th title="Обхват талии, см">Обхват талии, см</th>
                      {sizeList.waistCircumference.map((size, index) => <td key={size + index + "taliya"}>{size}</td>)}
                    </tr>
                    <tr>
                      <th title="Обхват бедер, см">Обхват бедер, см</th>
                      {sizeList.waistCircumference.map((size, index) => <td key={size + index + "bedra"}>{size}</td>)}
                    </tr>
                    <tr>
                      <th title="Обхват шеи, см">Обхват шеи, см</th>
                      {sizeList.neckCircumference.map((size, index) => <td key={size + index + "sheya"}>{size}</td>)}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h2 className={styles.subtitle}>Как снять мерки</h2>
            <p className={styles.text}>
              Чтобы выбрать правильный размер одежды, рекомендуем снять следующие ниже мерки при помощи сантиметровой
              ленты.
            </p>
            <div className={styles.description}>
              <div className={styles.text_wrapper}>
                <p className={styles.text_title}>1. Обхват груди</p>
                <p>
                  Сантимертровая лента должна проходить по наиболее выступающим точкам груди, сбоку - под подмышечными
                  впадинами, обхватывая лопатки сзади.
                </p>
                <p className={styles.text_title}>2. Обхват талии</p>
                <p>
                  Измеряется горизонтально в самой узкой части талии. При измерении лента должна плотно (без натяжения)
                  прилегать к телу.
                </p>
                <p className={styles.text_title}>3. Обхват бедер</p>
                <p>Сантиметровая лента проходит строго горизонтально по наиболее выступающим точкам ягодиц.</p>
              </div>
              <Image src={li("sizes-img-clothes.webp")} width={50} height={50} alt="Размер одежды" className={styles.img} />
            </div>
          </>}
      </div>
    </Modal>;
};
export default SizesTablePopup;