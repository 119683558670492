import styles from "./index.module.scss";
import { FC } from "react";
import SkeletonLoader from "@/shared/components/skeleton-loader";
const SkeletonCreateAdPopup: FC = () => {
  return <div className={styles.wrap} data-sentry-component="SkeletonCreateAdPopup" data-sentry-source-file="index.tsx">
      <div className={styles.content}>
        <div className={styles.items}>
          <SkeletonLoader containerTag="div" height={48} borderRadius={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
          <SkeletonLoader containerTag="div" height={48} borderRadius={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
          <SkeletonLoader containerTag="div" height={48} borderRadius={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
          <SkeletonLoader containerTag="div" height={48} borderRadius={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
          <SkeletonLoader containerTag="div" height={48} borderRadius={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
          <SkeletonLoader containerTag="div" height={48} borderRadius={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
          <SkeletonLoader containerTag="div" height={48} borderRadius={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
          <SkeletonLoader containerTag="div" height={48} borderRadius={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
        </div>
        <div className={styles.price}>
          <SkeletonLoader containerTag="div" className={styles.price_block} height={126} borderRadius={8} mb={24} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
          <SkeletonLoader containerTag="div" height={104} mt={24} mb={24} borderRadius={8} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
        </div>
        <SkeletonLoader containerTag="div" height={48} mb={24} borderRadius={8} width={200} data-sentry-element="SkeletonLoader" data-sentry-source-file="index.tsx" />
      </div>
    </div>;
};
export default SkeletonCreateAdPopup;