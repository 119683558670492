import { createServerCookie } from "@/app/actions";
import { setCookie } from "@/utils/functions/cookie";

export const setDefaultCity = async (name?: string) => {
  const cookie = {
    name: name || "Москва",
    code: "moskva",
    lat: "55.7522",
    lon: "37.6156",
  };
  createServerCookie("choosedCity", JSON.stringify(cookie)).then((r) => r);
  setCookie("choosedCity", JSON.stringify(cookie));
};
