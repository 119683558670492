"use client";

import styles from "./index.module.scss";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@/shared/components/Icon";
import { useElPositionRender } from "@/hooks/useElPositionRender";
type Position = "topLeft" | "topRight" | "bottomLeft" | "bottomRight" | "leftTop" | "leftBottom" | "rightTop" | "rightBottom";
type Color = "white" | "gray";
interface IHintPopup {
  isOpen: boolean;
  setIsOpen?: (state: boolean) => void;
  position: Position;
  color: Color;
  children: [React.ReactNode, React.ReactNode | string];
  hasCloseButton?: boolean;
  onClose?: () => void;
  triggerForTrigger?: any[];
}
const HintPopup = (props: IHintPopup) => {
  const {
    isOpen,
    color,
    children,
    hasCloseButton,
    onClose,
    setIsOpen,
    position = "topLeft",
    triggerForTrigger = []
  } = props;
  const [isClient, setIsClient] = useState<boolean>(false);
  const [pos, setPos] = useState<string>(position);
  const triggerRef = useRef(null);
  const contentRef = useRef(null);
  const calculatedPosition = useElPositionRender(position);
  const [trigger, content] = children;
  const presetPosition = position;
  const hintClassNames = classNames({
    [styles.hint]: true,
    [styles.hintVisible]: isOpen
  });
  const handleClickClose = () => {
    if (setIsOpen) {
      setIsOpen(false);
    }
    if (onClose) {
      onClose();
    }
  };
  const getPosition = () => {
    if (triggerRef.current && contentRef.current && isOpen) {
      calculatedPosition.getPosition(contentRef.current, triggerRef.current, presetPosition);
    }
  };
  useEffect(() => {
    if (window && isClient && triggerRef.current && contentRef.current && isOpen) {
      getPosition();
      window.onscroll = () => {
        getPosition();
      };
      window.onresize = () => {
        getPosition();
      };
    }
  }, [isClient, triggerRef.current, triggerRef.current, isOpen, pos]);
  useEffect(() => {
    setPos(calculatedPosition.positionElement);
  }, [calculatedPosition.positionElement, isOpen]);
  useEffect(() => {
    if (!isClient) {
      setIsClient(true);
    }
  }, []);
  return <div className={styles.container} data-sentry-component="HintPopup" data-sentry-source-file="index.tsx">
      <div className={styles.triggerContainer} ref={triggerRef}>
        {trigger}
      </div>

      {isClient ? <div ref={contentRef} className={`${hintClassNames} ${styles[pos]}`} onMouseOver={() => !hasCloseButton && setIsOpen ? setIsOpen(true) : null} onMouseOut={() => !hasCloseButton && setIsOpen ? setIsOpen(false) : null}>
          <div className={`${styles.hintTriangle} ${color === "gray" && styles.hintTriangleGray}`}></div>
          <div className={`${styles.hintContentContainer} ${color === "gray" && styles.hintContentContainerGray}`}>
            {hasCloseButton && <Icon name={"Close"} className={styles.closeIcon} onClick={() => handleClickClose()} />}
            {content}
          </div>
        </div> : null}
    </div>;
};
export default HintPopup;