"use client";

import { FC, useRef } from "react";
import styles from "./index.module.scss";
import Link from "next/link";
import replaceSymbolsTel from "@/utils/functions/replace-symbols-tel";
import { Icon } from "@/shared/components/Icon";
import { IShop } from "@/shared/types/types";
interface IShopComponent {
  shop: IShop;
  openBalloon?: (id: string, b: number[]) => void;
  showInfo?: boolean;
  isPreview?: boolean;
  showPhone?: boolean;
}
const Shop: FC<IShopComponent> = props => {
  const {
    shop,
    openBalloon = () => {},
    showInfo = true,
    isPreview = false,
    showPhone = true
  } = props;
  const refTel = useRef<HTMLAnchorElement>(null);
  const handleButtonClick = () => {
    ym(42989679, "reachGoal", "aboutStore");
  };
  const handleOpenBalloon = () => {
    openBalloon(shop.id, [Number(shop.gps_s), Number(shop.gps_n)]);
  };
  return <div className={styles.store} onClick={handleOpenBalloon} itemProp="address" itemScope itemType="https://schema.org/LocalBusiness" data-sentry-component="Shop" data-sentry-source-file="index.tsx">
      <meta itemProp="name" content={"ОхотАктив"} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta itemProp="telephone" content={shop.phone} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta itemProp="openingHours" content={shop.schedule_eng} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <Icon name={"Tag"} className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
      <div className={styles.storeInfo} itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
        <meta itemProp="streetAddress" content={shop.address} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <address className={styles.address}>
          {shop.address}
          {isPreview && showPhone ? <span>Телефон: {shop.phone} </span> : null}
          <span> Время работы: {shop.schedule} </span>
        </address>
        {shop.phone && showInfo && !isPreview && <>
            <p className={styles.phone}>Телефон:</p>
            <a href={`tel:${replaceSymbolsTel(shop.phone)}`} className={styles.link} ref={refTel} itemProp="telephone">
              {shop.phone}
            </a>
          </>}
        {isPreview ? <p className={styles.quantity}>В наличии {shop.quantity} шт.</p> : null}
        {!isPreview ? showInfo ? <Link prefetch={false} onClick={handleButtonClick} href={`/shops/${shop.value}/`} className={styles.shop_link}>
              Подробнее о магазине
            </Link> : <Link prefetch={false} onClick={handleButtonClick} target="_blank" href={`/shops/${shop.value}/`} className={styles.shop_link} rel="noopener">
              Подробнее о магазине
            </Link> : null}
      </div>
    </div>;
};
export default Shop;