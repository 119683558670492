import styles from "./index.module.scss";
import Modal from "@/shared/components/Modal";
import Input from "@/shared/components/Input";
import { FC, useEffect, useRef, useState } from "react";
import { IPopup } from "@/shared/types/types";
import Button from "@/shared/components/Button";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { getUserInfo } from "@/shared/redux/features/userSlice";
import sortList from "./sortList.json";
import { checkUploadForm } from "@/utils/functions/check-upload-form";
import { editAnnouncement, postAnnouncement } from "@/utils/api/postAnnouncement";
import { callMessage } from "@/utils/functions/callMessage";
import { isEmptyObject } from "@/utils/functions/isEmptyObj";
import InputUploader from "@/shared/components/InputUploader";
import SkeletonCreateAdPopup from "@/shared/components/skeleton-loader/tamplates/CreateAdPopupSkeleton";
import SelectComponent from "@/shared/components/Select";
import { Icon } from "@/shared/components/Icon";
import Link from "next/link";
interface ITypeMaterial {
  [key: string]: string;
}
const typeMaterial: ITypeMaterial = {
  metallic: "Металл",
  tree: "Дерево",
  plastic: "Пластик"
};
interface ISortList {
  [key: string]: Array<{
    value: string;
    label: string;
  }>;
}
type IFormList = {
  elemType: string;
  id: string;
  label: string;
  type: string;
  maxLength: number;
  minLength: number;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  autoComplete: string;
  [key: string]: any;
  ad: any;
  isNew: boolean;
  loading: boolean;
};
export const CreateAdPopup: FC<IPopup> = props => {
  const {
    isOpen,
    onClose,
    ad = {},
    isNew = false,
    loading = false
  } = props;
  const user = useAppSelector(getUserInfo).info;
  const [imagesArray, setImagesArray] = useState([]) as any;
  const [initialAd, setInitialAd] = useState(ad);
  const [model, setModel] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [manufacturer, setManufacturer] = useState<string>("");
  const [weaponLength, setWeaponLength] = useState<string>("");
  const [weight, setWeight] = useState<string>("");
  const [price, setPrice] = useState<string>(ad.price || "");
  const [description, setDescriptio] = useState<string>("");
  const dispatch = useAppDispatch();
  const formRef = useRef<any>(null);
  const [typeData, setTypeData] = useState<string>("");
  const [sortData, setSortData] = useState<ISortList>(sortList);
  const [isLoading, setIsLoading] = useState(false);
  const [initalTypeState, setInitalTypeState] = useState<string | any[]>([{}]);
  const [initialCalibrState, setInitialCalibrState] = useState<string | any[]>([{}]);
  const [initialMaterialState, setInitialMaterialState] = useState<string | any[]>([{}]);
  const [initialConditionState, setInitialConditionState] = useState<string | any[]>([{}]);
  const [show, setShow] = useState<boolean>(false);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let data: any = Object.fromEntries(formData.entries());
    const newFormData = new FormData();
    data.files = imagesArray;
    let a = typeMaterial[data.material];
    data.material = a;
    Object.entries(data).forEach(([key, value]) => {
      newFormData.append(key, value as string);
    });
    imagesArray.forEach((file: any, i: number) => {
      newFormData.append(`files[${i}]`, file);
    });
    if (checkUploadForm(data)) {
      setIsLoading(true);
      if (isNew) {
        editAnnouncement(ad.id, newFormData).then((res: any) => {
          setIsLoading(false);
          if (res && res.success) {
            callMessage("Успешно!", `Ваше объявление отправлено на модерацию. После проверки оно появится в разделе "Комиссионное оружие" и в личном кабинете в разделе "Мои объявления"`, true);
            ym(42989679, "reachGoal", "advertisement");
            onClose();
          }
        }).catch(err => {
          setIsLoading(false);
        });
      } else {
        postAnnouncement(newFormData).then((res: any) => {
          setIsLoading(false);
          if (res && res.success) {
            callMessage("Успешно!", `Ваше объявление отправлено на модерацию. После проверки оно появится в разделе "Комиссионное оружие" и в личном кабинете в разделе "Мои объявления"`, true);
            ym(42989679, "reachGoal", "advertisement");
            onClose();
          }
        }).catch(err => {
          setIsLoading(false);
        });
      }
    }
  };
  const handleSelectChange = (e: {
    value: string;
  }) => {
    setTypeData(e.value);
  };
  useEffect(() => {
    if (!isNew && loading) {
      setShow(true);
      return;
    }
    if (isNew && loading) {
      setShow(loading);
      return;
    }
  }, [isNew, loading]);
  useEffect(() => {
    if (!loading) return;
    if (!isEmptyObject(ad)) {
      setInitalTypeState("");
      setInitialCalibrState("");
      setInitialMaterialState("");
      setInitialConditionState("");
      return;
    }
    const initalType = isEmptyObject(ad) ? sortList.type.filter(item => item.value === ad.type.value) : "";
    const initialCalibr = isEmptyObject(ad) ? sortList.smoothbore.filter(item => item.value === ad.caliber_other.value) : "";
    const initialMaterial = isEmptyObject(ad) ? sortList.material.filter(item => item.label === ad.priklad_other.value) : "";
    const initialCondition = isEmptyObject(ad) ? sortList.conditions.filter(item => item.value === ad.condition.value) : "";
    setInitalTypeState(initalType);
    setInitialCalibrState(initialCalibr);
    setInitialMaterialState(initialMaterial);
    setInitialConditionState(initialCondition);
    const newFiles = ad.files ? ad.files : [];
    setImagesArray([...newFiles, {
      value: ad.preview_picture
    }]);
    setModel(ad.model_other.value || "");
    if (ad.citys) {
      setCity(ad.citys.value || "");
    }
    setManufacturer(ad.brand.value || "");
    setPrice(ad.price);
    setWeaponLength(ad.length.value || "");
    setWeight(ad.weight.value || "");
    setDescriptio(ad.comment.value || "");
  }, [ad, isNew, loading]);
  return <Modal isOpen={isOpen} title={"Новое объявление"} onClose={onClose} data-sentry-element="Modal" data-sentry-component="CreateAdPopup" data-sentry-source-file="index.tsx">
      <div className={styles.wrap}>
        <form className={styles.form} onSubmit={handleSubmit} ref={formRef}>
          {!show ? <SkeletonCreateAdPopup /> : <>
              <div className={styles.inputList}>
                <Input id="model-input" label={"Модель"} className={styles.inputWrap}>
                  <input id="model-input" type="text" maxLength={20} minLength={2} className={styles.input} placeholder=" " value={model} onChange={(e: any) => setModel(e.target.value)} name="model" autoComplete="off" />
                </Input>
                <Input id={"City-input"} label="Город" className={styles.inputWrap}>
                  <input id={"City-input"} type="text" maxLength={40} minLength={2} className={styles.input} placeholder=" " value={city} onChange={(e: any) => setCity(e.target.value)} name="city" autoComplete="off" />
                </Input>
                <SelectComponent isSearchable={false} defaultValue={initalTypeState[0]} isChoosed={initalTypeState[0]} options={sortData.type} name="type" className={styles.select} isInForm={true} placeholder={"Вид оружия"} onChange={handleSelectChange} />
                <SelectComponent isSearchable={false} defaultValue={initialCalibrState[0]} isChoosed={initialCalibrState[0]} options={!!typeData ? sortData[typeData] : sortData.smoothbore} name={"calibr"} className={styles.select} isInForm={true} placeholder={"Калибр"} />
                <SelectComponent isSearchable={false} defaultValue={initialMaterialState[0]} isChoosed={initialMaterialState[0]} options={sortData.material} name={"material"} className={styles.select} isInForm={true} placeholder={"Материал приклада"} />
                <Input id="manufacturer-input" label="Производитель" className={styles.inputWrap}>
                  <input id="manufacturer-input" type="text" maxLength={40} minLength={2} className={styles.input} placeholder=" " value={manufacturer} onChange={(e: any) => setManufacturer(e.target.value)} name="manufacturer" autoComplete="off" />
                </Input>
                <Input id="weaponLength-input" label="Длина оружия, мм" className={styles.inputWrap}>
                  <input id="weaponLength-input" type="text" maxLength={40} minLength={2} className={styles.input} placeholder=" " value={weaponLength} onChange={(e: any) => setWeaponLength(e.target.value)} name="length" autoComplete="off" />
                </Input>
                <Input id="price-input" label="Цена" className={styles.inputWrap}>
                  <input id="price-input" type="number" maxLength={40} minLength={2} className={styles.input} placeholder=" " value={price} onChange={(e: any) => setPrice(e.target.value)} name="price" autoComplete="off" />
                </Input>
                <SelectComponent isSearchable={false} defaultValue={initialConditionState[0]} isChoosed={initialConditionState[0]} options={sortData.conditions} name={"condition"} className={styles.select} isInForm={true} placeholder={"Состояние"} />
                <Input id="weight-input" label="Вес, кг" className={styles.inputWrap}>
                  <input id="weight-input" type="number" maxLength={40} minLength={2} className={styles.input} placeholder=" " value={weight} onChange={e => setWeight(e.target.value)} name="weight" autoComplete="off" />
                </Input>
              </div>
              <div className={styles.textareaWrap}>
                <textarea className={`${styles.textarea} ${description.length < 20 && styles.textarea_invalid}`} name="description" placeholder="Добавьте описание" rows={4} value={description} onChange={e => setDescriptio(e.target.value)}></textarea>
              </div>
              <InputUploader imagesArray={imagesArray ? imagesArray : []} setImagesArray={setImagesArray} />
              <Button type="submit" className={styles.button} disabled={isLoading}>
                <>Разместить объявление</>
              </Button>
              <Link className={styles.rules} href="/rules-for-comission/">
                <Icon name={"circleAlert"} className={styles.icon} />
                Правила размещения объявлений
              </Link>
            </>}
        </form>
      </div>
    </Modal>;
};