"use client";

import styles from "./index.module.scss";
import Modal from "@/shared/components/Modal";
import Input from "@/shared/components/Input";
import { FC, useEffect, useRef, useState } from "react";
import { IGeoCity, IPopup } from "@/shared/types/types";
import { setCity } from "@/shared/redux/features/citySlice";
import { useAppDispatch } from "@/shared/redux/hooks";
import { clearItemsInfo, setStep } from "@/shared/redux/features/orderSlice";
import { getShops } from "@/utils/api/shops";
import useDebouncedFunction from "@/shared/hooks/useDebouncedFunction";
import { getGeo } from "@/utils/api/dadata";
import { translit } from "@/utils/functions/translit";
import { useRouter } from "next/navigation";
import { Icon } from "@/shared/components/Icon";
import GeoPopupSkeleton from "@/shared/components/skeleton-loader/tamplates/GeoPopupSkeleton";
import { createServerCookie } from "@/app/actions";
import { setDefaultCity } from "@/utils/functions/setDefaultCity";
import { setCookie } from "@/utils/functions/cookie";
interface IInputEvent {
  target: {
    value: string;
  };
}
type geoEl = {
  data: {
    city: string;
  };
};
export const GeoPopup: FC<IPopup> = props => {
  const {
    isOpen,
    onClose
  } = props;
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [allCities, setAllCities] = useState<IGeoCity[]>([]);
  const [filteredCities, setFilteredCities] = useState<IGeoCity[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [citySearch, setCitySearch] = useState("");
  const loadShops = async () => {
    await getShops().then(res => {
      setAllCities(res.items);
      setFilteredCities(res.items);
      setIsLoading(false);
    });
  };
  const handleClick = async (city: IGeoCity) => {
    try {
      const findShop = allCities.find(shop => shop.name === city.name);
      dispatch(setCity({
        name: city.name,
        code: city.translit_title
      }));
      dispatch(clearItemsInfo());
      dispatch(setStep(1));
      if (typeof window !== "undefined" && window.ym) {
        window.ym(42989679, "reachGoal", "changeCity");
      }
      if (findShop) {
        const cookie = {
          name: city.name,
          code: city.translit_title,
          lat: findShop.lat,
          lon: findShop.lon
        };
        try {
          await createServerCookie("choosedCity", JSON.stringify(cookie));
        } catch (err) {
          console.log(err);
        } finally {
          setCookie("choosedCity", JSON.stringify(cookie));
          onClose();
        }
      } else {
        await setDefaultCity(city.name);
        onClose();
      }
    } catch (err) {
      await setDefaultCity(city.name);
      console.log(err);
    } finally {
      onClose();
      document.body.style.overflow = "auto";
    }
  };
  const debounce = useDebouncedFunction(city => {
    if (city.length === 0) {
      setFilteredCities(allCities);
    } else {
      getGeo(city).then(res => {
        const cities = res.suggestions.map((el: geoEl) => ({
          name: el.data.city,
          translit_title: translit(el.data.city)
        }));
        setCitySearch(city);
        setFilteredCities(cities);
      });
    }
  }, 500);
  const handleInputChange = (event: IInputEvent) => {
    const value = event.target.value;
    debounce(value);
  };
  useEffect(() => {
    loadShops();
    inputRef.current?.focus();
  }, []);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);
  const closeModal = () => {
    onClose();
    document.body.style.overflow = "auto";
  };
  return <Modal isOpen={isOpen} title={"Выберите свой город"} onClose={closeModal} containerClassname={styles.modal_container} headerClassname={styles.header} titleClassname={styles.title} className={styles.modal} data-sentry-element="Modal" data-sentry-component="GeoPopup" data-sentry-source-file="index.tsx">
      <div className={styles.wrap}>
        <p className={styles.text}>
          Это нужно для того чтобы заранее увидеть актуальные цены, способы получения и наличие товаров.
        </p>
        {isLoading ? <GeoPopupSkeleton /> : <>
            <div className={styles.inputInfoWrap}>
              <Input id={"geo-popup-city"} label={"Введите город"} className={styles.inputWrap}>
                <input id={"geo-popup-city"} type="text" maxLength={50} className={styles.input} placeholder=" " onChange={handleInputChange} ref={inputRef} />
              </Input>
              <div className={styles.info}>
                <Icon name={"City"} />
                <p className={styles.infoText}>города, в которых есть магазин «ОхотАктив»</p>
              </div>
            </div>
            <div className={styles.defaultCities}>
              <div className={styles.defaultCitiesList}>
                {filteredCities.map((el, index) => {
              if (el.name.includes("км")) return;
              const findShop = allCities.find(shop => shop.name === el.name);
              return <button key={`${index + "buttonkeySearchList" + "geopopup"}`} className={styles.defaultCitiesButton} onClick={() => handleClick(el)}>
                      {findShop ? <Icon name={"City"} /> : <span className={styles.empty}></span>}
                      {el.name}
                      {/* {citySearch && (
                       <span className={styles.region}>
                       {" "}
                       {el.data.region_with_type}
                       </span>
                       )} */}
                    </button>;
            })}
              </div>
            </div>
          </>}
      </div>
    </Modal>;
};