export const sizeList = {
  footLength: [
    "24,5",
    "24,7",
    "25",
    "25,3",
    "25,5",
    "26,3",
    "26,7",
    "27",
    "27,5",
    "27,7",
    "28",
    "28,3",
    "28,7",
    "29",
    "29,4",
    "29,7",
    "30",
    "30,5",
    "31",
  ],
  footRuSize: [
    "38",
    "38,5",
    "39",
    "39,5",
    "40",
    "41",
    "41,5",
    "42",
    "42,5",
    "43",
    "43,5",
    "44",
    "44,5",
    "45",
    "45,5",
    "46",
    "46,5",
    "47",
    "48",
  ],
  footEuSize: [
    "39",
    "39,5",
    "40",
    "40,5",
    "42",
    "42,5",
    "43",
    "43,5",
    "44",
    "44,5",
    "45",
    "45,5",
    "46",
    "46,5",
    "47",
    "47,5",
    "48",
    "49",
  ],
  footUkSize: ["6", "6.5", "7", "7.5", "8.5", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "13.5"],
  footUsSize: ["6.5", "7", "7.5", "8", "9", "9.5", "10", "10.5", "11", "11.5", "12", "12.5", "13", "13.5", "14"],
  ruSize: [
    "44",
    "46",
    "48",
    "50",
    "52",
    "54",
    "56",
    "58",
    "60",
    "62",
    "64",
    "66",
    "68",
    "70",
    "72",
    "74",
    "76",
    "78",
    "80",
    "82",
  ],
  INT: ["XS", "S", "M", "L", "L/XL", "XL", "XXL", "XXXL", "4XL", "5XL", "6XL", "7XL", "8XL", "9XL", "10XL"],
  chestCircumference: [
    "88-91",
    "92-95",
    "96-99",
    "100-103",
    "104-107",
    "108-111",
    "112-115",
    "116-119",
    "120-124",
    "125-128",
    "128-131",
    "132-135",
    "136-139",
    "140-143",
    "144-147",
    "148-151",
    "152-155",
    "156-159",
    "160-163",
    "164-167",
  ],
  waistCircumference: [
    "78-81",
    "82-84",
    "85-87",
    "88-90",
    "91-94",
    "95-99",
    "100-107",
    "107-110",
    "111-115",
    "116-120",
    "121-125",
    "126-130",
    "131-136",
    "137-140",
    "141-144",
    "145-148",
    "149-152",
    "153-156",
    "157-160",
    "161-164",
  ],
  hipCircumference: [
    "94-97",
    "98-101",
    "102-105",
    "106-108",
    "109-112",
    "112-116",
    "116-120",
    "121-124",
    "124-128",
    "129-133",
    "133-134",
    "134-136",
    "137-140",
    "141-144",
    "145-148",
    "149-152",
    "153-156",
    "157-160",
    "161-164",
    "165-168",
  ],
  neckCircumference: [
    "38",
    "39",
    "40",
    "41",
    "41/42",
    "42",
    "43",
    "43/44",
    "44/45",
    "45/46",
    "47/48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
  ],
};
