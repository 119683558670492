"use client";

import { YMapMarker } from "ymap3-components";
import styles from "./index.module.scss";
import React, { JSX, ReactElement } from "react";
import { Feature, LngLat } from "@yandex/ymaps3-types";
import { Icon } from "@/shared/components/Icon";
interface IPoint extends Feature {
  name: string;
  location: {
    address: string;
  };
  "address-source": string;
  geometry: {
    type: "Point";
    coordinates: LngLat;
  };
  color: string;
  id: string;
}
interface IMapMarker {
  openPopup?: () => void;
  point: IPoint;
  handleChoose?: () => void;
  isOpenPopup?: false | string;
  handleClosePopup?: () => void;
  customPopup?: ReactElement | JSX.Element | any;
}
const CustomMapMarker = (props: IMapMarker) => {
  const {
    openPopup,
    point,
    handleChoose,
    isOpenPopup,
    handleClosePopup,
    customPopup
  } = props;
  const popup = customPopup ? customPopup : <YMapMarker zIndex={1_000} key={point.name + "popup"} coordinates={point.geometry.coordinates}>
      <div className={styles.custom_balloon} style={{
      zIndex: 1_000
    }}>
        <div className={styles.balloon_wrap}>
          <p className={styles.name}>{point.name}</p>
          <p>{point.location && point.location.address ? point.location.address : point["address-source"]}</p>
          <p className={styles.time}>Время работы</p>
          <button className={styles.close} onClick={handleClosePopup}>
            <Icon name={"Close"} />
          </button>
          <button className={styles.choose} onClick={handleChoose}>
            Выбрать
          </button>
        </div>
      </div>
    </YMapMarker>;
  const isLro = point.color === "#E54F6D";
  return <>
      <YMapMarker key={point.name} coordinates={point.geometry.coordinates} zIndex={0} data-sentry-element="YMapMarker" data-sentry-source-file="index.tsx">
        <div className={styles.marker_wrap}>
          <button onClick={openPopup} className={styles.custom_marker}>
            <Icon name={"Tag"} className={`${isLro ? styles.lro : styles.club}`} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
          </button>
        </div>
      </YMapMarker>
      {isOpenPopup === point.id && popup}
    </>;
};
export default CustomMapMarker;