import { useEffect, useState } from "react";
import useDebouncedFunction from "@/hooks/useDebouncedFunction";

export const useElPositionRender = (presetPosition: string | null) => {
  const [clientHeight, setClientHeight] = useState<number>(0);
  const [clientWidth, setClientWidth] = useState<number>(0);
  const [positionElement, setPositionElement] = useState<string>(presetPosition ?? "bottomLeft");

  const positionConfig = {
    topLeft: (triggerRect: DOMRect, elContentHeight: number, elContentWidth: number) =>
      triggerRect.top > elContentHeight + 118 && triggerRect.left > elContentWidth - 100,
    topRight: (triggerRect: DOMRect, elContentHeight: number, elContentWidth: number) =>
      triggerRect.top > elContentHeight + 118 && clientWidth - triggerRect.right >= elContentWidth - 100,
    bottomLeft: (triggerRect: DOMRect, elContentHeight: number, elContentWidth: number) =>
      clientHeight - triggerRect.bottom >= elContentHeight && triggerRect.left > elContentWidth - 100,
    bottomRight: (triggerRect: DOMRect, elContentHeight: number, elContentWidth: number) =>
      clientHeight - triggerRect.bottom >= elContentHeight && clientWidth - triggerRect.right >= elContentWidth - 100,
    leftTop: (triggerRect: DOMRect, elContentHeight: number, elContentWidth: number) =>
      triggerRect.left > elContentWidth + 50 && triggerRect.top > elContentHeight + 50,
    rightTop: (triggerRect: DOMRect, elContentHeight: number, elContentWidth: number) =>
      clientWidth - triggerRect.right > elContentWidth + 50 && triggerRect.top > elContentHeight + 50,
    leftBottom: (triggerRect: DOMRect, elContentHeight: number, elContentWidth: number) =>
      triggerRect.left > elContentWidth + 50 && clientHeight - triggerRect.bottom >= elContentHeight - 50,
    rightBottom: (triggerRect: DOMRect, elContentHeight: number, elContentWidth: number) =>
      clientWidth - triggerRect.right > elContentWidth + 50 &&
      clientHeight - triggerRect.bottom >= elContentHeight - 50,
  };

  const getPosition = (el: HTMLElement | null, trigger: HTMLElement | null, presetPosition?: string) => {
    if (!el || !trigger) return;

    const triggerRect = trigger.getBoundingClientRect();
    const elContentHeight = el.clientHeight + 10;
    const elContentWidth = el.clientWidth;

    setClientHeight(document.documentElement.clientHeight);
    setClientWidth(document.documentElement.clientWidth);

    const fallbackOrder = [
      presetPosition,
      "topRight",
      "bottomRight",
      "topLeft",
      "bottomLeft",
      "leftBottom",
      "rightBottom",
      "leftTop",
      "rightTop",
    ].filter(Boolean);

    for (const position of fallbackOrder) {
      if (positionConfig[position as keyof typeof positionConfig]?.(triggerRect, elContentHeight, elContentWidth)) {
        setPositionElement(position as string);
        return;
      }
    }

    setPositionElement("bottomRight");
  };

  const debouncedGetPosition = useDebouncedFunction(getPosition, 200);

  useEffect(() => {
    window.addEventListener("wheel", debouncedGetPosition);
    window.addEventListener("resize", debouncedGetPosition);

    return () => {
      window.removeEventListener("wheel", debouncedGetPosition);
      window.removeEventListener("resize", debouncedGetPosition);
    };
  }, []);

  return { positionElement, getPosition };
};
