import styles from "./index.module.scss";
import Modal from "@/shared/components/Modal";
import { FC } from "react";
import { IPopup } from "@/shared/types/types";
export const DeliveryPopup: FC<IPopup> = ({
  isOpen,
  onClose
}) => {
  return <Modal isOpen={isOpen} title={"Доставка ОхотАктив"} onClose={onClose} data-sentry-element="Modal" data-sentry-component="DeliveryPopup" data-sentry-source-file="index.tsx">
      <div className={styles.wrap}>
        <p className={styles.text}>
          Доставка осуществляется собственной логистической компанией с удаленного склада до магазинов розничной сети
          «ОхотАктив». Срок доставки зависит от удаленности магазинов.
        </p>
        <p className={styles.smallText}>* Не распространяется на услугу «Перемещение»</p>
      </div>
    </Modal>;
};