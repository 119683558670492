import { TModalCloseComponent } from "@/shared/types/types";
import styles from "./index.module.scss";
import { FC } from "react";
import { Icon } from "@/shared/components/Icon";
const ModalClose: FC<TModalCloseComponent> = props => {
  const {
    onClose,
    closeColor
  } = props;
  return <button onClick={onClose ? onClose : () => {}} className={styles.close} data-sentry-component="ModalClose" data-sentry-source-file="index.tsx">
      <Icon name={"Close"} fill={closeColor} className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
    </button>;
};
export default ModalClose;