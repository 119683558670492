"use client";

import classNames from "classnames";
import { FC } from "react";
import Select from "react-select";
import "./select.scss";
interface ISelectComponent {
  isSearchable: boolean;
  options: any;
  name: string;
  className: string;
  isChoosed: number;
  onChange?: (x: any) => void;
  isInForm: boolean;
  placeholder: string;
  defaultValue: string;
}
const SelectComponent: FC<ISelectComponent> = props => {
  const {
    isSearchable,
    options,
    name,
    className,
    isChoosed,
    onChange,
    isInForm,
    placeholder,
    defaultValue = ""
  } = props;
  const selectClassnames = classNames({
    "react-select-container": isInForm,
    [className!]: className
  });
  return <Select components={{
    IndicatorSeparator: () => null
  }} styles={{
    control: base => ({
      ...base,
      border: 0,
      boxShadow: "none"
    })
  }} classNamePrefix="react-select" className={selectClassnames} name={name} instanceId={name} isSearchable={isSearchable} options={options} defaultValue={defaultValue || options[isChoosed]} onChange={onChange} placeholder={placeholder} data-sentry-element="Select" data-sentry-component="SelectComponent" data-sentry-source-file="index.tsx" />;
};
export default SelectComponent;