import styles from "./index.module.scss";
import Modal from "@/shared/components/Modal";
import Image from "next/image";
import { li } from "@/utils/functions/li";
import { callMessage } from "@/utils/functions/callMessage";
import { Icon } from "@/shared/components/Icon";
interface INewUserPopup {
  onClose: () => void;
  isOpen: boolean;
  coupon?: string | boolean;
}
const NewUserPopup = (props: INewUserPopup) => {
  const {
    onClose,
    isOpen,
    coupon
  } = props;
  const handleCopy = () => {
    if (!coupon || typeof coupon === "boolean") return;
    navigator.clipboard.writeText(coupon).then();
    callMessage("Успешно!", "Промокод скопирован.", true);
  };
  return <Modal onClose={onClose} isOpen={isOpen} className={styles.container} containerClassname={styles.modal_container} data-sentry-element="Modal" data-sentry-component="NewUserPopup" data-sentry-source-file="index.tsx">
      <div className={styles.wrap}>
        <Image src={li("coupon-gift.webp")} alt={"Подарок"} width={169} height={154} className={styles.img} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        <div className={styles.wrapInfo}>
          <p className={styles.title}>Вы успешно зарегистрировались!</p>
          <p className={styles.text}>Ваш промокод на 1 000₽ при покупке от 5 000₽ будет действовать 3 дня</p>
        </div>
        <div className={styles.couponContainer}>
          <p className={styles.coupon}>{coupon}</p>
          <button onClick={handleCopy}>
            <Icon name={"Copy"} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
          </button>
        </div>
      </div>
    </Modal>;
};
export default NewUserPopup;