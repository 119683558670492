"use client";

import { createPortal } from "react-dom";
import styles from "./index.module.scss";
import { FC, useEffect, useState } from "react";
import { IModalComponent } from "@/shared/types/types";
import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import Overlay from "@/shared/components/Modal/components/Overlay";
import Header from "@/shared/components/Modal/components/Header";
import Close from "@/shared/components/Modal/components/Close";
const Modal: FC<IModalComponent> = props => {
  const {
    isOpen,
    children,
    title,
    onClose,
    className,
    closeColor,
    isHeader = true,
    containerClassname,
    showClose = true,
    overlayClass = "",
    headerClassname,
    titleClassname
  } = props;
  const wrapClassnames = classNames({
    [styles.modal]: true,
    [className!]: true
  });
  const [modalRoot, setModalRoot] = useState<any>();
  const dropIn = {
    hidden: {
      top: "48%",
      opacity: 0
    },
    visible: {
      top: "50%",
      opacity: 1
    },
    exit: {
      top: "48%",
      opacity: 0
    }
  };
  useEffect(() => {
    setModalRoot(document.getElementById("modals"));
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        if (onClose) {
          onClose();
        }
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  if (!modalRoot) {
    return null;
  }
  return createPortal(<>
      <AnimatePresence>
        {isOpen && <motion.div>
            <Overlay onClose={onClose ? onClose : () => {}} overlayClass={overlayClass} />
            <motion.div key="modal" className={wrapClassnames} variants={dropIn} initial="hidden" animate="visible" exit="exit" onClick={e => e.stopPropagation()}>
              <div className={`${styles.modal_container} ${containerClassname}`}>
                {!!title ? <Header title={title} headerClassname={headerClassname} titleClassname={titleClassname} /> : null}
                {children}
              </div>
              {showClose ? <Close onClose={onClose} closeColor={closeColor} /> : null}
            </motion.div>
          </motion.div>}
      </AnimatePresence>
    </>, modalRoot);
};
export default Modal;