import Modal from "@/shared/components/Modal";
import styles from "./index.module.scss";
import { FC } from "react";
interface IUnavailableCartItemPopup {
  isOpen: boolean;
  onClose: () => void;
  typeAvailable: boolean;
}
const UnavailableCartItemPopup: FC<IUnavailableCartItemPopup> = props => {
  const {
    isOpen,
    onClose,
    typeAvailable
  } = props;
  return <Modal isOpen={isOpen} title={typeAvailable ? "Недоступно для заказа в вашем городе" : "Недоступно для заказа"} onClose={onClose} containerClassname={styles.modalContainer} data-sentry-element="Modal" data-sentry-component="UnavailableCartItemPopup" data-sentry-source-file="index.tsx">
      <div className={styles.wrap}>
        {typeAvailable ? <p className={styles.text}>
            В данном магазине запрещена продажа пороха. Получить такой товар можно только в том магазине, где он есть в
            наличии.
          </p> : <p className={styles.text}>
            Этот товар не перемещается между магазинами розничной сети {`"ОхотАктив"`}. Получить такой товар можно
            только в том магазине, где он есть в наличии.
          </p>}
      </div>
    </Modal>;
};
export default UnavailableCartItemPopup;