import { IPopup } from "@/shared/types/types";
import { FC, useRef, useState } from "react";
import { useFetchData } from "@/shared/hooks/useFetchData";
import { useAppSelector } from "@/shared/redux/hooks";
import { getCity } from "@/shared/redux/features/citySlice";
import { getDeliveryPointsRequest } from "@/utils/api/order";
import { PointsList } from "./PointsList";
import styles from "./index.module.scss";
import Modal from "@/shared/components/Modal";
import Loading from "@/shared/components/Loader";
export const ChooseDeliveryPopup: FC<IPopup> = ({
  isOpen,
  onClose,
  company
}) => {
  const city = useAppSelector(getCity).city;
  const arg = company === "boxberry" ? city : company === "cdek" ? city : company === "pochta" ? city : "";
  const {
    isLoading,
    items
  } = useFetchData(getDeliveryPointsRequest, {
    company,
    arg
  });
  const pointListRef = useRef(true);
  const [prepare, setPrepare] = useState(false);
  const handleClose = () => {
    setPrepare(true);
    const timer = setTimeout(() => onClose());
    return () => clearTimeout(timer);
  };
  return <Modal isOpen={isOpen} title={"Пункты самовывоза"} onClose={handleClose} className={styles.wrap} containerClassname={styles.container} data-sentry-element="Modal" data-sentry-component="ChooseDeliveryPopup" data-sentry-source-file="index.tsx">
      {isLoading && !prepare ? <Loading type={"preLoader"} /> : <PointsList points={items} company={company} onClose={handleClose} prepare={prepare} setPrepare={setPrepare} pointListRef={pointListRef} />}
    </Modal>;
};