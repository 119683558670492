"use client";

import { Accordion } from "@szhsin/react-accordion";
import styles from "./index.module.scss";
import React, { FC, FormEvent, useState } from "react";
import { IShop, IStories } from "@/shared/types/types";
import Input from "@/shared/components/Input";
import AccordionItem from "@/shared/components/AccordionItem";
import { Icon } from "@/shared/components/Icon";
import { AvailablePopupStore } from "@/shared/components/popups/available-popup/AvailablePopupStore";
import Shop from "@/shops/components/Shop";
interface IAccordionShop {
  stores: IStories[];
  setCenter: (a: number[]) => void;
  openBalloon: (id: string, b: number[]) => void;
  isSearch?: boolean;
  style?: string;
  wareHouse?: boolean;
  isPreview?: boolean;
  showPhone?: boolean;
}
const warehouseData = {
  title: "Удаленный склад",
  value: "",
  schedule: "",
  quantity: ""
};
const AccordionShop: FC<IAccordionShop> = props => {
  const {
    stores,
    setCenter,
    openBalloon,
    isSearch = true,
    style = "",
    wareHouse = false,
    isPreview = false,
    showPhone = true
  } = props;
  const [currentShops, setCurrentShops] = useState(stores);
  const handleInput = (e: FormEvent<HTMLInputElement>) => {
    e.currentTarget.value = e.currentTarget.value.replace(/[^а-я, ^А-Я, a-z, A-Z '']/, "");
    setCurrentShops(stores.filter((stores: IStories) => stores.name.toLowerCase().includes(e.currentTarget.value.toLowerCase())));
  };
  return <div className={styles.list} data-sentry-component="AccordionShop" data-sentry-source-file="index.tsx">
      {isSearch ? <Input id="input-shops" label="Введите город" icon={<Icon name={"Search"} />} className={styles.inputWrap}>
          <input id="input-shops" type="text" maxLength={50} className={styles.input} onChange={handleInput} placeholder=" " />
        </Input> : null}
      <Accordion className={`${styles.accordion} ${style}`} transition transitionTimeout={250} data-sentry-element="Accordion" data-sentry-source-file="index.tsx">
        {wareHouse ? <AvailablePopupStore store={warehouseData} warehouse key={`${"Удаленный склад" + "liStoreModal" + "availablePopup"}`} /> : null}
        {currentShops.map((store: IStories, indexCurrent: number) => {
        const firstShop = store.items.length ? store.items[0] : false;
        const storeCords = [Number(store.lon), Number(store.lat)];
        const firstShopCords = firstShop ? [Number(firstShop.gps_s), Number(firstShop.gps_n)] : storeCords;
        return <AccordionItem key={`${indexCurrent + store.name + store.lat + store.lon}`} header={store.name} contentProps={{
          className: styles.itemContent
        }} buttonClick={() => setCenter(firstShop ? firstShopCords : storeCords)} className={styles.accordion_item}>
              <div className={styles.accordionPanel}>
                {store.items.map((shop: IShop, index: number) => {
              return <Shop key={`${index + "AccordionItem" + "AccordionShop"}`} shop={shop} openBalloon={openBalloon} isPreview={isPreview} showPhone={false} />;
            })}
              </div>
            </AccordionItem>;
      })}
      </Accordion>
    </div>;
};
export default AccordionShop;