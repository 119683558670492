import { AccordionItem as Item } from "@szhsin/react-accordion";
import styles from "./index.module.scss";
import { Icon } from "@/shared/components/Icon";
const AccordionItem = (props: any) => {
  const {
    header,
    buttonClick,
    ...rest
  } = props;
  const buttonProps = () => {
    let props: any = {
      className: ({
        isEnter
      }: {
        isEnter: boolean;
      }) => `${styles.itemButton} ${isEnter && styles.itemButtonOpened}`
    };
    if (buttonClick) {
      props.onClick = buttonClick;
    }
    return props;
  };
  const contentProps = () => {
    let props: any = {
      className: styles.itemContent
    };
    if (rest.contentProps) {
      props.className = `${styles.itemContent} ${rest.contentProps.className}`;
    }
    return props;
  };
  return <Item {...rest} header={<>
          {header}
          <Icon name={"Chevron"} className={styles.chevron} />
        </>} className={rest.className ? styles.item + " " + rest.className : styles.item}
  // Сделал так, потому что типы явно не прописаны и в одном месте потребовалось обработать
  // клик кастомно, поэтому так
  // если меняешь проверь везде ли работает
  // обрати внимание на каталог и <AvailablePopup/>
  buttonProps={buttonProps()} contentProps={contentProps()} panelProps={{
    className: styles.itemPanel
  }} data-sentry-element="Item" data-sentry-component="AccordionItem" data-sentry-source-file="index.tsx" />;
};
export default AccordionItem;