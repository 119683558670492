import styles from "./index.module.scss";
import Modal from "@/shared/components/Modal";
import { FC, useEffect } from "react";
import { phoneFormat } from "@/utils/functions/phone-format";
const SellerPopup: FC<any> = props => {
  const {
    isOpen,
    onClose,
    sellerInfo
  } = props;
  useEffect(() => {
    ym(42989679, "reachGoal", "showTelSellerFromComission");
  }, []);
  return <Modal isOpen={isOpen} title={sellerInfo.phone ? phoneFormat(sellerInfo.phone) : "Телефон не указан"} onClose={onClose} data-sentry-element="Modal" data-sentry-component="SellerPopup" data-sentry-source-file="index.tsx">
      <div className={styles.wrap}>
        <p className={styles.text}>Имя: {sellerInfo.name ? sellerInfo.name : "Имя не указано"}</p>
        <p className={styles.text}>Email: {sellerInfo.email ? sellerInfo.email : "Email не указан"}</p>
        <p className={styles.text}>Не сообщайте продавцу данные своей банковской карты.</p>
      </div>
    </Modal>;
};
export default SellerPopup;