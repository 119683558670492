import styles from "./index.module.scss";
import Modal from "@/shared/components/Modal";
import { FC } from "react";
import { IPopup } from "@/shared/types/types";
export const CourierPopup: FC<IPopup> = ({
  isOpen,
  onClose
}) => {
  return <Modal isOpen={isOpen} title={"Доставка курьером"} onClose={onClose} data-sentry-element="Modal" data-sentry-component="CourierPopup" data-sentry-source-file="index.tsx">
      <div className={styles.wrap}>
        <p className={styles.text}>
          Вы можете выбрать доставку курьером до двери или до пункта выдачи заказов любой транспортной компании.
          Стоимость доставки рассчитывается согласно тарифам выбранной вами транспортной компании.
        </p>
        <p className={styles.smallText}>* Не распространяется на лицензионные товары</p>
      </div>
    </Modal>;
};