import styles from "./index.module.scss";
import { IButton } from "@/shared/types/types";
import React, { FC } from "react";
import clsx from "clsx";
const Button: FC<IButton> = React.forwardRef((props, ref: React.Ref<HTMLButtonElement>) => {
  const {
    id,
    children,
    className,
    onClick,
    type = "button",
    disabled,
    ...rest
  } = props;
  return <button {...rest} type={type} id={id} className={clsx(styles.button, className)} onClick={onClick} disabled={disabled} ref={ref}>
      {children}
    </button>;
});
Button.displayName = "Button";
export default Button;