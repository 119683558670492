import { IShop } from "@/shared/types/types";
import styles from "./index.module.scss";
import { FC } from "react";
import Link from "next/link";
import { Icon } from "@/shared/components/Icon";
interface IAvailablePopupStore {
  store: IShop | {
    value: string;
    title: string;
    schedule: string;
    quantity: string;
  };
  warehouse?: boolean;
}
export const AvailablePopupStore: FC<IAvailablePopupStore> = props => {
  const {
    store,
    warehouse = false
  } = props;
  return <li className={styles.store_wrap} data-sentry-component="AvailablePopupStore" data-sentry-source-file="index.tsx">
      <Icon name={"Tag"} className={styles.icon} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
      <div className={styles.info}>
        {warehouse ? <p className={styles.name}>{store.title}</p> : <Link prefetch={false} className={styles.name} href={`/shops/${store.value}`}>
            {store.title}
          </Link>}
        {!warehouse && <p className={styles.schedule}>{store.schedule}</p>}
        {!warehouse && <p className={styles.quantity}>в наличии {store.quantity} шт.</p>}
      </div>
    </li>;
};