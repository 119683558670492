import classNames from "classnames";
import styles from "./index.module.scss";
import { IInputComponent } from "@/shared/types/types";
import { FC } from "react";
const Input: FC<IInputComponent> = props => {
  const {
    children,
    icon,
    label,
    required,
    id,
    className,
    labelClassName = ""
  } = props;
  const inputWrapClassnames = classNames(`${className ? className + " " : ""}${styles.wrap}`);
  return <div className={inputWrapClassnames} data-sentry-component="Input" data-sentry-source-file="index.tsx">
      {children}
      {icon ? icon : null}
      <label className={`${styles.label} ${labelClassName}`} htmlFor={id}>
        {label}
        {required && "*"}
      </label>
    </div>;
};
export default Input;