import { TModalHeaderComponent } from "@/shared/types/types";
import styles from "./index.module.scss";
import { FC } from "react";
import { Icon } from "@/shared/components/Icon";
const Header: FC<TModalHeaderComponent> = props => {
  const {
    title,
    goBack,
    onClose,
    headerClassname,
    titleClassname
  } = props;
  return <div className={`${styles.modalHeader} ${headerClassname}`} data-sentry-component="Header" data-sentry-source-file="index.tsx">
      {goBack && <button className={styles.title} onClick={onClose ? onClose : () => null}>
          <Icon name={"Arrow"} />
        </button>}
      <p className={`${styles.title} ${titleClassname}`}>{title}</p>
    </div>;
};
export default Header;