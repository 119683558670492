import styles from "./index.module.scss";
import Modal from "@/shared/components/Modal";
import Input from "@/shared/components/Input";
import React, { FC, useState } from "react";
import { IPopup } from "@/shared/types/types";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import { getUserInfo, updateUser } from "@/shared/redux/features/userSlice";
import { callMessage } from "@/utils/functions/callMessage";
import Button from "@/shared/components/Button";
interface IValues {
  name: string;
  last_name: string;
  address: string;
}
export const ChangeUserPopup: FC<IPopup> = ({
  isOpen,
  onClose
}) => {
  const user = useAppSelector(getUserInfo).info;
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>(user.name);
  const [lastName, setLastName] = useState<string>(user.lastname);
  const [address, setAddress] = useState<string>(user.address);
  const [isLoading, setIsLoading] = useState(false);
  const handleBlur = (e: React.FormEvent) => {
    e.preventDefault();
    const values: IValues = {
      name: name,
      last_name: lastName,
      address: address
    };
    if (!values.name) {
      return callMessage("Ошибка!", "Заполните поле имя.", false);
    }
    if (!values.last_name) {
      return callMessage("Ошибка!", "Заполните поле фамилия.", false);
    }
    if (!values.address) {
      return callMessage("Ошибка!", "Заполните поле адрес.", false);
    }
    setIsLoading(true);
    dispatch(updateUser(values)).then(() => {
      setIsLoading(false);
      onClose();
    });
  };
  return <Modal isOpen={isOpen} title={"Личные данные"} onClose={onClose} data-sentry-element="Modal" data-sentry-component="ChangeUserPopup" data-sentry-source-file="index.tsx">
      <div className={styles.wrap}>
        <form className={styles.form} onSubmit={handleBlur}>
          <Input id={"Name-input"} label={"Имя"} className={styles.inputWrap} data-sentry-element="Input" data-sentry-source-file="index.tsx">
            <>
              <input id="name-input" type="text" maxLength={50} className={styles.input} placeholder=" " value={name} onChange={e => setName(e.target.value)} name="name" autoComplete="off" />
            </>
          </Input>
          <Input id={"lastname-input"} label={"Фамилия"} className={styles.inputWrap} data-sentry-element="Input" data-sentry-source-file="index.tsx">
            <input id="lastname-input" type="text" maxLength={50} className={styles.input} placeholder=" " value={lastName} onChange={e => setLastName(e.target.value)} name="last_name" autoComplete="off" />
          </Input>
          <Input id={"address-input"} label={"Адрес доставки"} className={styles.inputWrap} data-sentry-element="Input" data-sentry-source-file="index.tsx">
            <input id="address-input" type="text" maxLength={100} className={styles.input} placeholder=" " value={address} onChange={e => setAddress(e.target.value)} name="personal_street" autoComplete="off" />
          </Input>
          <Button type="submit" className={styles.button} disabled={isLoading} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            <>Изменить</>
          </Button>
        </form>
      </div>
    </Modal>;
};